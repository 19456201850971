import React, { useState } from "react";
import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Heading,
  Input,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import axios from "axios";

export default function Contact({ color }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const toast = useToast();

  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitDisabled(true)

    const contactFormString = `-- New message from EGONZALEZTECH contact form --\nName: ${name}\nEmail: ${email}\nMessage: ${message}`;

    try {
      await axios.post(process.env.REACT_APP_SLACK_BACKEND_URL, {
        text: contactFormString,
      });
      toast({
        title: t('contact.success_title'),
        description: t('contact.success_message'),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Clear the form fields
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
      toast({
        title: "Error.",
        description: "There was an error sending your message. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsSubmitDisabled(false)
  };

  return (
    <>
      <Container maxW={"3xl"} id="contact">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pb={{ base: 20, md: 36 }}
        >
          <Stack align="center" direction="row" p={4}>
            <HStack>
              <Text fontWeight={800}>{t('contact.contact')}</Text>
            </HStack>
            <Divider orientation="horizontal" />
          </Stack>
          <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
            <Heading mb={2} fontSize={"3xl"}>{t('contact.get_in_touch')}</Heading>
            <form onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Input
                  placeholder={t('contact.name')}
                  size="lg"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <Input
                  placeholder="Email"
                  type="email"
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <Textarea
                  placeholder={t('contact.message')}
                  size="lg"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
                <Button
                  type="submit"
                  colorScheme={color}
                  size="lg"
                  mt={4}
                  isDisabled={isSubmitDisabled}
                >
                  {t('contact.submit')}
                </Button>
              </Stack>
            </form>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}
