import {
  Box,
  Container,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        align="center"
      >
        <Text fontSize={"sm"}>Better Call Edu LLC</Text>
        <Text fontSize={"sm"}>5203 Juan Tabo Blvd. Suite 2b</Text>
        <Text fontSize={"sm"}>{t('footer.city')}</Text>
        <Text fontSize={"sm"}>{t('footer.united_states')}</Text>
        <Text fontSize={"sm"}>© {currentYear}</Text>
      </Container>
    </Box>
  );
}
