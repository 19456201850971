// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      navbar: {
        about: 'About',
        companies: 'Companies',
        contact: 'Contact',
      },
      contact: {
        contact: 'Contact',
        get_in_touch: 'Get in touch',
        name: 'Name',
        message: 'Message',
        submit: 'Submit',
        success_title: 'Message sent',
        success_message: 'We got your message and will contact you soon.',
      },
      footer: {
        city: "Albuquerque, New Mexico 87111",
        united_states: 'United States',
      }
    },
  },
  es: {
    translation: {
      navbar: {
        about: 'Sobre mí',
        companies: 'Empresas',
        contact: 'Contacto',
      },
      contact: {
        contact: 'Contacto',
        get_in_touch: 'Póngase en contacto',
        name: 'Nombre',
        message: 'Mensaje',
        submit: 'Enviar',
        success_title: 'Mensaje enviado',
        success_message: 'Gracias por tu mensaje. Nos pondremos pronto en contacto contigo'
      },
      footer: {
        city: "87111 Albuquerque (Nuevo Mexico)",
        united_states: 'Estados Unidos',
      }
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
