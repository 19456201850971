import React, { useEffect, useState } from 'react';
import './App.css';
import Nav from './components/NavBar';
import Header from './components/Hero';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import i18n from './components/i18n';

import navigatorLanguage from "./utils/navigatorLanguage";

function App() {
  // Available Colours:
  // blue, cyan, gray, green, orange, pink, purple, red, teal, yellow

  // edit this variable to change the color theme
  const color = "yellow";

  const [key, setKey] = useState(0);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);

    // Increment key to force re-render
    setKey(key + 1);
  };

  useEffect(() => {
    const navigatorLanguageCode = navigatorLanguage();

    changeLanguage(navigatorLanguageCode)
  }, [])

  

  return (
    <>
      <Nav color={color} changeLanguage={changeLanguage} />
      <div key={key}>
        <Header color={color} />
        <About color={color} />
        <Projects color={color} />
        <Contact color={color} />
      </div>
      <Footer />
    </>
  );
}

export default App;
