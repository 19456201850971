const languagesData = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "es",
    name: "Español",
    country_code: "es",
  },
  {
    code: "nl",
    name: "Nederlands",
    country_code: "nl",
  },
];

export default function navigatorLanguage() {
  const navigatorLanguage =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;
  let navigatorLanguageCode = navigatorLanguage.slice(0, 2);
  const languagesSupported = languagesData.map((language) => language.code);

  if (!languagesSupported.includes(navigatorLanguageCode)) {
    navigatorLanguageCode = "en";
  }
  
  return navigatorLanguageCode
}
