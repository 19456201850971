import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Flex,
  Image,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import ProfileArray from "./ProfileArray";

export default function About({ color }) {
  const { t } = useTranslation();

  const profile = ProfileArray();
  const paragraphs = profile.about.split("\n");

  return (
    <>
      <Container maxW={"3xl"} id="about">
        <Stack
          as={Box}
          textAlign={"left"}
          spacing={{ base: 8, md: 14 }}
          pb={{ base: 20, md: 36 }}
        >
          <Stack align="center" direction="row">
            <HStack mx={4}>
              <Text fontWeight={800} style={{ whiteSpace: 'nowrap' }}>{t('navbar.about')}</Text>
            </HStack>
            <Divider orientation="horizontal" />
          </Stack>
          <Flex direction="column" px={4}>
            <Flex align="left" direction={['column', 'row']} mb={4}>
              <Text order={[2, 1]} color={"gray.500"} fontSize={"md"} mr={1}>
                {paragraphs[0]}<br/><br/>
                {paragraphs[1]}
              </Text>
              <Image order={[1, 2]} alignSelf="center"
                src="../assets/me.jpg"
                alt="Eduardo Gonzalez"
                borderRadius="full"
                boxSize="220px"
                mb={7}
              />
            </Flex>
            {paragraphs.slice(2).map((paragraph, index) => (
              <Text color={"gray.500"} fontSize={"md"} key={index} mb={4}>
                {paragraph}
              </Text>
            ))}
          </Flex>
        </Stack>
      </Container>
    </>
  );
}
