// Nav.js

import {
  Flex,
  Button,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  useColorModeValue,
  Stack,
  useColorMode,
  IconButton,
  useMediaQuery,
  useDisclosure,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon, HamburgerIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import ProfileArray from './ProfileArray';
import i18n from './i18n';
import { useTranslation } from "react-i18next";

const TbIcons = require('react-icons/tb');

export default function Nav({ color, changeLanguage }) {
  const { t } = useTranslation();

  const profile = ProfileArray();
  const colors = {
    blue: '#3182CE',
    cyan: '#00B5D8',
    gray: '#718096',
    green: '#38A169',
    orange: '#DD6B20',
    pink: '#D53F8C',
    purple: '#805AD5',
    red: '#E53E3E',
    teal: '#319795',
    yellow: '#D69E2E',
  };

  const [scroll, setScroll] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  const scrollToSection = (id) => {
    const section = document.querySelector(`#${id}`);
    section.scrollIntoView({ behavior: 'smooth' });
  };

  const changeScroll = () =>
    window.pageYOffset > 80 ? setScroll(true) : setScroll(false);

  useEffect(() => {
    window.addEventListener('scroll', changeScroll);
    return () => {
      window.removeEventListener('scroll', changeScroll);
    };
  }, []);

  const TbLetterComponents = [];

  for (let i = 0; i < profile.logo.length; i++) {
    const letter = profile.logo[i];
    const component = TbIcons[`TbLetter${letter}`];
    TbLetterComponents.push(component);
  }

  return (
    <>
      <Flex
        bg={useColorModeValue('gray.100', 'gray.900')}
        px={4}
        h={16}
        boxShadow={scroll ? 'base' : 'none'}
        zIndex='sticky'
        position='fixed'
        as='header'
        alignItems='center'
        justifyContent='space-between'
        w='100%'
      >
        <HStack>
          {TbLetterComponents.map((Component, index) => (
            <Component key={index} color={colors[color]} />
          ))}
        </HStack>

        <Flex alignItems='center'>
          <Stack direction='row' spacing={5}>
            <Menu>
              <MenuButton as={Button} rightIcon={<span>&#9660;</span>}>
                {i18n.language === 'en' ? 'English' : 'Español'}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
                <MenuItem onClick={() => changeLanguage('es')}>Español</MenuItem>
              </MenuList>
            </Menu>
            {isLargerThanMD && (
              <>
                <Button variant='ghost' onClick={() => scrollToSection('about')}>
                  {t('navbar.about')}
                </Button>
                <Button variant='ghost' onClick={() => scrollToSection('projects')}>
                  {t('navbar.companies')}
                </Button>
                <Button variant='ghost' onClick={() => scrollToSection('contact')}>
                  {t('navbar.contact')}
                </Button>
              </>
            )}

            <Button onClick={toggleColorMode}>
              {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            </Button>

            {isLargerThanMD ? (
              <></>
            ) : (
              <>
                <Button
                  as={IconButton}
                  icon={<HamburgerIcon />}
                  onClick={onOpen}
                ></Button>
                <Drawer placement='top' onClose={onClose} isOpen={isOpen}>
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerBody>
                      <Button
                        variant='ghost'
                        onClick={() => {
                          scrollToSection('about');
                          onClose();
                        }}
                      >
                        {t('navbar.about')}
                      </Button>
                      <Button
                        variant='ghost'
                        onClick={() => {
                          scrollToSection('projects');
                          onClose();
                        }}
                      >
                        {t('navbar.companies')}
                      </Button>
                      <Button
                        variant='ghost'
                        onClick={() => {
                          scrollToSection('contact');
                          onClose();
                        }}
                      >
                        {t('navbar.contact')}
                      </Button>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
              </>
            )}
          </Stack>
        </Flex>
      </Flex>
    </>
  );
}
